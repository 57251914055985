import * as React from "react"

export function ArticleTitle({ type, title }) {
  return (
    <div className="text-base max-w-prose mx-auto lg:max-w-none">
      <p className="text-base text-primary-500 font-semibold tracking-wide uppercase">{type}</p>
      <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
    </div>
  )
}

export function ArticleContent({ children }) {
  return <div className="mt-8 prose prose-primary prose-lg text-gray-500 max-w-prose lg:max-w-none">{children}</div>
}
